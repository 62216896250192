import React from 'react'
import { Link } from '@reach/router'

function NavLink(props) {
  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        return {
          className: isCurrent ? `${props.className} current` : props.className,
        }
      }}
    />
  )
}

function overrideEventHandlers (props) {
  let isMiddleClick = false

  const onMouseDown = (e) => {
    if (e.button === 1) {
      isMiddleClick = true
    }

    if (props.onMouseDown) {
      props.onMouseDown(e, props)
    }
  }

  const onClick = (e) => {
    const { altKey, ctrlKey, metaKey, shiftKey } = e
    const modifiers = [altKey, ctrlKey, metaKey, shiftKey]

    if (props.onClick) {
      props.onClick(e, props)
    }

    /**
     * Run something on click, but only if it's the primary mouse button
     * without any modifiers. Modifier keys change browser behaviour, and do not
     * trigger a route change.
     */
    if (!isMiddleClick && !modifiers.some(Boolean)) {
      // noop
    }
  }

  return {
    onClick,
    onMouseDown,
  }
}

export default function MyLink (props) {
  const {
    to,
    children,
    className,
    nav = false,

    onKeyDown,
    onClick,
  } = props

  const isExternal = to.indexOf('http') === 0
  const Tag = !isExternal ? nav ? NavLink : Link : 'a'
  const tagProps = isExternal ? {
    href: to,
    target: '_blank',
    rel: 'noreferrer noopener',
    className,
    onKeyDown,
    onClick,
  } : {
    to: to,
    className,
    onKeyDown,
    ...overrideEventHandlers({
      ...props,
      onClick,
    }),
  }

  return (
    <Tag {...tagProps}>
      {children}
    </Tag>
  )
}
