export const statusEnum = {
  uninitialized: -1,
  ready: 0,
  error: 1,
  loading: 2,
}

/**
 * Helper for translating values to key names; not sure if useful in any way
 */
export const e = (x, from = statusEnum) => {
  return Object.entries(from).find(([k, v]) => v === x)[0]
}
