import React, { Component } from 'react'
import { connect } from 'kea'
import { Helmet } from 'react-helmet'

import applicationLogic from 'logic/app'
// import { isError } from 'lodash'

import { getErrorFromMessage, is } from 'lib/error'

import './Hack.scss'

const kea = {
  actions: [
    applicationLogic, [
      'runCommand',
    ],
  ],

  props: [
    applicationLogic, [
      'commands',
    ]
  ]
}

export default connect(kea)(class Hack extends Component {
  renderRow = (obj, i) => {
      if (is.error(obj.output)) {
      const { message } = obj.output

      return (
        <div key={`badkey-${i}`}>
          <strong>{obj.command}</strong><br />

          <p>{getErrorFromMessage(message)} error: {message}</p>
        </div>
      )
    }

    return (
      <div key={`badkey-${i}`}>
        <strong>{obj.command}</strong><br />
        {obj.output}
      </div>
    )
  }

  render() {
    const { commands } = this.props
    const { history, output } = commands

    const data = history.map((command, i) => ({
      command,
      output: output[i],
    }))

    // console.log('help', data)

    return (
      <div className="Hack">
        <Helmet>
          <meta name="http:status" content="200" />
          <title>Script kiddie playground | kisu.li</title>
        </Helmet>
        {data.map(this.renderRow)}
      </div>
    )
  }
})
