import React from 'react'
import { Router, Location } from '@reach/router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import './AnimatedRouters.scss'

const TransitionRouter = ({ children, primary = true, classNames = 'RouteTransition RouteTransition' }) => (
  <Location>
    {({ location }) => (
      <TransitionGroup className="TransitionGroup">
        <CSSTransition key={location.key} classNames={classNames} timeout={500} appear={true}>
          {/* the only difference between a router animation and
              any other animation is that you have to pass the
              location to the router so the old screen renders
              the "old location" */}
          <Router location={location} primary={primary} className={[
              'Router',
              `page-${location.pathname}`,
            ].join(' ')}>
            {children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
)

export {
  TransitionRouter,
}
