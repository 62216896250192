import config from './config'
import axios from 'axios'

import { error } from './error'

const { backend, wpUrl } = config

/**
 * Naive caching solution. Lost on refresh, but *tiny* compared to all other solutions.
 * Use localForage for persistent cache, but bear in mind that that will save a lot of data
 * to the users device, which is somewhat hard to get rid off.
 *
 * Alternatively:
 * https://github.com/kuitos/axios-extensions
 * https://www.npmjs.com/package/lru-cache
 */
function cachedGet (get) {
  // const cache = new Map()

  return function cached (url) {
    // const key = url

    // if (cache.has(key)) {
      // return cache.get(key)
    // } else {
      const request = get(...arguments)

      // cache.set(key, request)

      return request
    // }
  }
}

class APIClient {
  constructor({ backend }) {
    this.client = axios.create({
      // baseURL: `${window.location.protocol}//${backend}`,
      baseURL: backend,
      withCredentials: true,
      // validateStatus: (status) => status >= 200 && status < 500, // We'll handle these ourselves,
      validateStatus: (status) => true, // Validate everything manually
      xsrfCookieName: '_csrf',
      // xsrfHeaderName: '_csrf',
    })

    this.client.get = cachedGet(this.client.get)
  }

  errorHandler = (e) => {
    if (e.message === 'Network Error') {
      throw error.networkError(e)
    }

    throw e
  }

  parseRequest(request) {
    const { status, data } = request

    switch (status) {
      case 404: {
        throw error.notFound(data) // Some endpoints send a 404 status to indicate that no such resource exists. An error message may exist in the data.
      }

      case 400: {
        throw error.badRequest(data) // Same here
      }

      default: {
        if (status === 500) {
          throw error.internalServerError(data)
        } else if (status === 504) {
          // nginx broken
          throw error.gatewayTimeout(data)
        } else if (status === 502) {
          // service down
          throw error.badGateway(data)
        }

        if (!(status >= 200 && status < 300)) {
          console.error('Unspecified network error occurred', request)
          throw error.invalidResponse(data)
        }
      }
    }

    return request
  }

  async get(url, reqOptions = {}) {
    try {
      let req = await this.client.get(url, reqOptions)
      req = this.parseRequest(req)

      return req
    } catch (e) {
      this.errorHandler(e)
    }
  }

  async post(url, data = {}, reqOptions = {}) {
    try {
      const req = await this.client.post(url, data, reqOptions)

      return req
    } catch (e) {
      this.errorHandler(e)
    }
  }

  async login(username, password) {
    return this.post('/login', { username, password })
  }

  async logout() {
    return this.post('/logout')
  }

  async gainAccessTo(domain = 'somestaging', passphrase = 'mellon') {
    return this.post('/gain-access', { domain, passphrase })
  }

  async getMenu(slug = 'menu') {
    return this.get(`/wp/wp-json/k1/v1/menu/get/${slug}`)
  }

  async resolveURL(url = '/') {
    if (url.indexOf('http') === 0) {
      // Good to go!
    } else {
      const startsWithSlash = url.indexOf('/') === 0

      url = wpUrl + (startsWithSlash ? '' : '/') + url
    }

    return this.get(`/wp/wp-json/k1/v1/resolver/url?url=${encodeURIComponent(url)}`)
  }
}

const instance = new APIClient({
  backend,
})

window.api = instance // Expose for lolz. This makes it available to the "console".
export default instance
