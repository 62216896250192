import React, { Component } from 'react'
import { connect } from 'kea'
import Typewriter from 'typewriter-effect'

import applicationLogic from './logic/app'
import { statusEnum } from 'lib/enums'
// import debugRender from 'react-render-debugger'

import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'

import WordPress from './containers/WordPress'
import Hack from './containers/Hack'

import ErrorBoundary from './components/ErrorBoundary'
import Navigation from './components/Navigation'
import { TransitionRouter } from './components/AnimatedRouters'


import './App.scss';
import { isPrerendered } from 'lib/env'

const kea = {
  actions: [
    applicationLogic, [
      'initialize',
      'removeError',
    ],
  ],

  props: [
    applicationLogic, [
      'appStatus',
      'errors',
    ]
  ]
}

export default connect(kea)((class App extends Component {
  componentDidMount() {
    if (!isPrerendered()) {
      console.log('is not prerendered, initing')
      this.actions.initialize()
    } else {
      console.log('is prerendered')
    }
  }

  render() {
    const {
      errors,
      appStatus,
    } = this.props
    const { removeError } = this.actions

    const classNames = [
      "App",
    ].join(' ')

    return (
      <React.Fragment>
        <SkipNavLink />
        <Navigation />

        <main className={classNames}>
          <SkipNavContent />
          <ErrorBoundary status={appStatus} errors={errors} removeError={(i = 0) => removeError(i)}>
            {appStatus === statusEnum.ready ? (
                <TransitionRouter>
                  <Hack path="/hack" />

                  <WordPress default />
                </TransitionRouter>
            ) : (
              <div className="init-text">
                <Typewriter
                  options={{
                    delay: 45,
                  }}
                  onInit={(x) => {
                    x
                      .typeString('init 5 <br/>')
                      .pauseFor(800)
                      .typeString('Starting... <br/><br/>')
                      .pauseFor(3000)
                      .typeString('WHAT IS TAKING SO LONG?! <br/><br/>')
                      .pauseFor(800)
                      .typeString('Maybe I\'m popular and my servers are down. Refreshing the page will likely make the problem worse. <br/>')
                      .pauseFor(800)
                      .typeString('ANY DAY NOW. <br/><br />')
                      .pauseFor(800)
                      .typeString('It\'s also possible that your internet connection is bad. Like 2G bad.<br/><br>')
                      .pauseFor(800)
                      .typeString('Waiting...<br/>')
                      .pauseFor(10000)
                      .typeString('Fuck it. It\'s not working. I\'ll refresh the page for you in a sec. <br/>')
                      .pauseFor(2000)
                      .callFunction(() => { window.location.reload() })
                      .start()
                  }}
                  />
              </div>
            )}
          </ErrorBoundary>
        </main>
      </React.Fragment>
    )
  }
}))
