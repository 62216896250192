import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ScrambledTitle from 'components/ScrambledTitle'


export default class FourOhOne extends Component {
  render() {
    return (
      <article className="Page401">
        <Helmet>
          <meta name="http:status" content="401" />

          <title>401 - YOU SHALL NOT PASS | kisu.li</title>
        </Helmet>
        <ScrambledTitle title={"401 - YOU SHALL NOT PASS!"} element="h1" />

        <p>Logging in with an authorized user probably helps.</p>
      </article>
    )
  }
}
