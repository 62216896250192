import React, { Component } from 'react'
import html from '../../../lib/html'
// import renderBlocks from 'lib/render-blocks'

export default class DefaultBlock extends Component {
  render() {
    const { rawHTML, innerBlocks } = this.props

    if (innerBlocks && innerBlocks.length) {
      return <p>This content is not supported.</p>
      // return (
      //   <React.Fragment>
      //     {innerBlocks.map(({ innerBlocks }) => renderBlocks(innerBlocks))}
      //   </React.Fragment>
      // )
    }

    return html(rawHTML)
  }
}
