import React, { Component } from 'react'
import Errors from 'components/Errors'

import { statusEnum } from 'lib/enums'

export default class ErrorBoundary extends Component {
  state = {
    error: null,
  }

  static defaultProps = {
    errors: [], // Optionally pass from Redux
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error, info) {
    console.log(error, info)
  }

  render() {
    const { errors, removeError, status } = this.props
    const { error } = this.state

    /**
     * If status property is provided, don't show errors
     * unless it's spesifically set to 'error'
     */
    if (status && status !== statusEnum.error) {
      return this.props.children
    }

    if (error || errors.length) {
      return (
        <Errors
          clearLast={errors.length && removeError ? () => removeError(0): null}
          errors={[
            error,
            ...errors,
          ].filter(x => x !== null)}
        />
      )
    }

    return this.props.children
  }
}
