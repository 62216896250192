export default function env() {
  return process.env.NODE_ENV
}

export function isProd() {
  return env() === 'production'
}

export function isDev() {
  return env() === 'development'
}

export function isPrerendered() {
  return Boolean(window.PRERENDERED)
}
