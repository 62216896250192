import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { getContext, resetContext } from 'kea'
import sagaPlugin from 'kea-saga'
import localStoragePlugin from 'kea-localstorage'

import * as serviceWorker from './serviceWorker'
import App from './App'


import './index.scss'
import { isPrerendered } from 'lib/env'

/**
 * https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
 */
if ('scrollRestoration' in window.history) {
  // Fuck off browser, I got this...
  // window.history.scrollRestoration = 'manual'
}



resetContext({
  // additional options (e.g. plugins, middleware, reducers, ...)
  createStore: true,
  defaults: !isPrerendered() ? {} : window.__PRELOADED_STATE__,
  plugins: [
    sagaPlugin,
    localStoragePlugin,
  ]
})

function Main ({ children }) {
  const { store } = getContext()

  // Dirty side effect, used by Puppeteer
  window.getState = () => store.getState()

  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

ReactDOM[isPrerendered() ? 'hydrate' : 'render'](
  <Main />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
// serviceWorker.register()

