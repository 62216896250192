import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import FocusTrap from 'react-focus-trap'
// import { isEqual } from 'lodash'
// import ScrollLock from 'react-scrolllock'
// import debounceRender from 'react-debounce-render'
// import renderDebug from 'react-render-debugger'

import { connect } from 'kea'
import applicationLogic from 'logic/app'

import Blocks from 'components/Blocks'
import { statusEnum } from 'lib/enums'
import ScrambledTitle from 'components/ScrambledTitle'
import ManageScroll from 'components/ManageScroll'
import Image from 'components/Image'

import FourOhFour from 'containers/404'
import FourOhOne from 'containers/401'
import Errors from 'components/Errors'
import { TransitionRouter } from 'components/AnimatedRouters'

import './WordPress.scss'
import relativeUrl from 'lib/relative-url'
import Link from 'components/Link';
import config from 'lib/config'

const isMoreView = (location = window.location) => location.pathname.indexOf('/more') !== -1

/**
 * In addition to WordPress URLs, this app supports /more/path/to/something
 * URLs, but that part has to be ripped off before using it.
 */

const getPageUrl = (location) => {
  const { pathname, search } = location
  const moreIndex = pathname.indexOf('/more/')


  if (moreIndex > -1) {
    // Remove /more and everything after it
    return pathname.substring(0, moreIndex + 1) + search
  }

  return pathname + search
}

/**
 * The first part of the URL is useless in More pages
 */
const getMoreUrl = (location) => {
  const { pathname, search } = location
  const moreIndex = pathname.indexOf('/more/')

  if (moreIndex > -1) {
    return pathname.substring(moreIndex + 5) + search  // + 5 to get rid of "/more" and keep "/"
  }

  return pathname + search
}

const AdminAdditions = ({ id, user }) => {
  if (!user || user.username === 'nobody' || !id) {
    return null;
  }

  return (
    <div className="AdminAdditions">
      <Link className="button" to={`${config.wpUrl}/wp-admin/post.php?post=${id}&action=edit`}>Edit post</Link>
    </div>
  )
}

class Base extends Component {
  render() {
    const { page, pageStatus, user, children } = this.props

    if (pageStatus === statusEnum.error) {
      switch (page.error) {
        // This is the only possible case at the moment
        case 'NOT_FOUND': {
          return <FourOhFour error={page} />
        }

        case 'AUTH_REQUIRED': {
          return <FourOhOne error={page} />
        }

        default: {
          // You shouldn't see this in practise. If you do, add a condition so it doesn't happen.
          return <Errors errors={[page]} />
        }
      }
    } else if (pageStatus === statusEnum.loading) {
      const title = 'Loading'
      const content = []
      const classNames = [
        'WordPress',
        'loading'
      ].join(' ')

      return (
        <React.Fragment>
          <Helmet>
            <meta name="http:status" content="200" />
            <title>{`${title} | kisu.li`}</title>
          </Helmet>

          <article className={classNames}>
            <header className="WordPressHeader">
              <ScrambledTitle title={title} element="h1" />
            </header>

            <Blocks content={content} />
          </article>
        </React.Fragment>
      )
    }

    const { title = 'Loading', seo, id } = page || {}

    return (
      <React.Fragment>
        <Helmet>
          <meta name="http:status" content="200" />
          <title>{!seo ? `${title} | kisu.li` : seo.title}</title>

          {!seo ? null : Object.entries(seo.meta).map(([k, v]) => (
            <meta name={k} content={v} key={k} />
          ))}
        </Helmet>

        {children}

        <AdminAdditions id={id} user={user} />
      </React.Fragment>
    )
  }
}

class Page extends Component {
  state = {}

  componentDidMount() {
    const { location, page } = this.props
    const { loadPage } = this.actions

    const currentPageUrl = getPageUrl(location)

    if (page && page.link) {
      if (relativeUrl(page.link) === currentPageUrl) {
        // same page, don't load page again
        return
      }
    }

    loadPage(currentPageUrl)
  }

  render() {
    const { page, pageStatus, user } = this.props
    const { content = [], title = 'Loading', featured_media: featuredImage } = page || {}
    const classNames = [
      'WordPress',
    ].join(' ')

    return (
      <Base pageStatus={pageStatus} page={page} user={user}>
        <article className={classNames}>
          <header className="WordPressHeader">
            <Image image={featuredImage} size="large" />
            <ScrambledTitle title={title} element="h1" />
          </header>

          <Blocks content={content} />
        </article>

        {/* <ManageScroll /> */}
      </Base>
    )
  }
}

class More extends Component {
  state = {}

  componentDidMount() {
    if (!isMoreView()) {
      return
    }

    const { location } = this.props
    const { loadPage } = this.actions

    loadPage(getMoreUrl(location), true)
  }

  render() {
    if (!isMoreView()) {
      return null
    }

    const { nextPage, nextPageStatus, location, user } = this.props
    const { content, title, featured_media: featuredImage } = nextPage || {}
    const classNames = [
      'WordPress',
      'More',
    ].join(' ')

    const parentUrl = getPageUrl(location)

    const gtfo = () => {
      this.props.navigate(parentUrl)
    }

    const view = (
      <Base pageStatus={nextPageStatus} page={nextPage} user={user}>
        <article className={classNames}>
          <header className="WordPressHeader">
            <ScrambledTitle title={title} element="h1" />

            <div className="FeaturedImage">
              <Image image={featuredImage} size="medium" />
            </div>
          </header>

          <Blocks content={content} />
          <button onClick={gtfo}>Go back to {parentUrl}</button>
        </article>
      </Base>
    )

    return (
      <FocusTrap onExit={gtfo} className="focus-trap">
        {/* <ScrollLock> */}
          {view}
        {/* </ScrollLock> */}
      </FocusTrap>
    )
  }
}

class Blog extends Component {
  render() {
    return (
      <article className="WordPress Blog">
        <ScrambledTitle title="Blog" element="h1" />

        <p>A post list here. Sometime soon I guess.</p>
      </article>
    )
  }
}

const WP = {
  Blog: (Blog),
  Page: (connect({
    actions: [
      applicationLogic, [
        'loadPage',
      ],
    ],

    props: [
      applicationLogic, [
        'pageStatus',
        'page',
        'user',
      ]
    ]
  })(Page)),
  More: connect({
    actions: [
      applicationLogic, [
        'loadPage',
      ],
    ],

    props: [
      applicationLogic, [
        'nextPageStatus',
        'nextPage',
        'user',
      ]
    ]
  })(More),
}

class Router extends Component {
  render() {
    const { location } = this.props

    return (
      <React.Fragment>
        <TransitionRouter primary={false}>
          {isMoreView(location) // Hack. The wildcard matches every time, and I can't get it working without it.
            ? <WP.More path="*/more/:moreUrl" />
            : null
          }
          <WP.Blog path="blog" />
          <WP.Page default />
        </TransitionRouter>

        <ManageScroll />
      </React.Fragment>
    )
  }
}

export default Router
