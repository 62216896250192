import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ScrambledTitle from 'components/ScrambledTitle'


export default class FourOhFour extends Component {
  render() {
    console.log(this.props)

    return (
      <article className="Page404">
        <Helmet>
          <meta name="http:status" content="404" />

          <title>404 | kisu.li</title>
        </Helmet>
        <ScrambledTitle title={"404"} element="h1" />

        <p>No content was found for the request URL.</p>
      </article>
    )
  }
}
