import { invert, get, camelCase } from 'lodash'

/**
 * Define severities for errors.
 */
const severity = {
  unrecoverable: 100, // Something went so horribly wrong that refreshing is the best course of action
  pita: 10, // Something went wrong, like the server failed to respond
  nobodycares: 1, // 401, empty result set, etc
}

/**
 * Define helpers for assigning severities to errors
 */
const unrecoverable = error => ({ error, severity: severity.unrecoverable, })
const pita = error => ({ error, severity: severity.pita })
const nobodycares = error => ({ error, severity: severity.nobodycares })

/**
 * Errors that your code throws.
 * The `errors` object is built from this, converting the constants to camelCase.
 * Usage: throw errors.badRequest(data)
 */
const applicationErrors = {
  BAD_REQUEST: pita('The server says your request sucks. Try again with a better one.'),
  NO_RESULTS: nobodycares('The server says there\'s no results.'),
  NOT_FOUND: pita('The server tried so hard, but it couldn\'t find the resource you were looking for.'),
  FORBIDDEN: pita('The server doesn\'t allow you here.'),
  AUTH_REQUIRED: pita('Accessing this requires authentication.'),
  INVALID_RESPONSE: unrecoverable('Somebody dropped the server. It responds with gibberish.'),
  UNKNOWN_ERROR: unrecoverable('Shit\'s broken yo. We have no idea what just happened, but something broke.'),
  CORS: unrecoverable('The request probably was succesful, but CORS settings are broken.'),
  INITIALIZATION_ERROR: unrecoverable('The application failed to start.'),
  NETWORK_ERROR: pita(`There's a problem with your internet connection.`),
  INTERNAL_SERVER_ERROR: pita('The server reported an internal server error.'),
  GATEWAY_TIMEOUT: pita('Somebody broke nginx. Refresh in a few minutes.'),
  BAD_GATEWAY: pita('Do microservices they said. It will be fun they said. The requested service is DEAD, just like your hopes and dreams of viewing this site right now.'),
  '1D10T': nobodycares('Do you even JS?'),
}

/**
 * Errors from 3rd party code
 */
const externalErrors = {

}

const allErrors = {
  ...applicationErrors,
  ...externalErrors,
}

/**
 * Create a fake error object. Real ones are pretty useless.
 */
const createError = (errorConstant, errorData = {}) => {
  const errorMessage = get(allErrors[errorConstant], 'error', allErrors['UNKNOWN_ERROR'].error)
  const fakeError = {
    message: errorMessage,
    error: errorConstant,
    severity: getErrorSeverity(errorConstant),
    data: errorData,
  }

  return fakeError
}

/**
 * Actual copying is a total PITA, it's much easier to create a new one with the data.
 */
const copyError = error => {
  const { message, data } = error
  const errorConstant = getErrorFromMessage(message)

  return createError(errorConstant, data)
}

/**
 * Get severity as a string
 */
const getErrorSeverity = (errorConstant) => {
  return invert(severity)[getErrorSeverityValue(errorConstant)]
}

/**
 * Get severity as a number
 */
const getErrorSeverityValue = (errorConstant) => {
  console.log(errorConstant)
  if (!errorConstant) {
    return severity.unrecoverable
  }

  return allErrors[errorConstant].severity
}

/**
 * Get the errorConstant from the error message
 */
const getErrorFromMessage = (message) => {
  const error = Object.entries(allErrors).find(([errorConstant, { error }]) => message === error)

  if (!error) {
    if (message && message.indexOf('has been blocked by CORS policy') !== -1) {
      return 'CORS'
    }

    return 'UNKNOWN_ERROR'
  }

  return error[0]
}

/**
 * Convenience severity checks
 */
const is = {
  unrecoverable: type => getErrorSeverityValue(type) === severity.unrecoverable,
  pita: type => getErrorSeverityValue(type) === severity.pita,
  nobodycares: type => getErrorSeverityValue(type) === severity.nobodycares,
  error: x => x.error && x.message && x.severity,
}

const error = Object.entries(allErrors).reduce((acc, [errorConstant, errorObject]) => {
  acc[camelCase(errorConstant)] = (data) => createError(errorConstant, data)

  return acc
}, {})

export {
  // allErrors as error,
  error,
  is,
  severity,
  getErrorSeverity,
  getErrorSeverityValue,
  getErrorFromMessage,
  createError,
  copyError,
}
