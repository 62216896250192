import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { is } from 'lib/error'


import './Errors.scss'

export default class Errors extends Component {
  render() {
    const { errors, clearLast = false } = this.props;

    if (!errors.length) {
      return null
    }

    return (
      <div className="Errors">
        <Helmet>
          <meta name="http:status" content="500" />
          <title>Error | kisu.li</title>
        </Helmet>

        <h2>Something is fucky</h2>

        {errors.map((e, i) => {
          const {
            error,
            name = 'Error', // Fallback in case a *real* error (new Error) leaks to this component, which should not happen
            message,
            severity,
            data,
           } = e
          const isLast = i === errors.length - 1

          return (
            <div className={`error error--severity-${severity}`} key={`error-${i}`}>
              <h3>{error || name}</h3>
              <p>{message}</p>

              {data ? (
                <React.Fragment>
                  <h4>Additional info</h4>
                  <pre>{JSON.stringify(data, null, 2)}</pre>
                </React.Fragment>
              ) : null}


              {isLast && clearLast && !is.unrecoverable(error) ? (
                <button onClick={clearLast}>
                  I DON'T CARE
                </button>
              ): null}
            </div>
          )
        })}
      </div>
    )
  }
}
