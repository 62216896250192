import React, { Component } from 'react'
import delay from '../../../lib/delay'

import './Scrambler.scss'

function random(min, max, blacklist = []) {
    const number = Math.round(Math.random() * (max - min) + min)

    if (blacklist.indexOf(number) > -1) {
      return random(min, max, blacklist)
    }

    return number;
}

function stringReplaceAt(string, index, character) {
  return string.substr(0, index) + character + string.substr(index + character.length)
}

export default class Scrambler extends Component {
  state = {
    original: null,
    scrambled: null,
  }

  async componentDidMount() {
    this.mounted = true;
    await this.changeState({
      original: this.props.scramble,
    })

    await delay(random(150, 650))
    this.scramble()
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.interval)
  }


  changeState(state) {
    return new Promise(resolve => {
      /**
       * Ugly hack to prevent "can't update unmounted state" errors, which occurs
       * if the user is quickly switching page.
       */
      if (!this.mounted) {
        return resolve()
      }

      this.setState(state, resolve)
    })
  }

  scramble = async () => {
    const { original } = this.state
    const words = original.split(' ')
    await this.changeState({
      scrambled: words.reduce((acc, word, i) => {
        const firstWord = words[i]
        const lastWord = words[words.length - 1]
        const isFirst = word === firstWord
        const isLast = word === lastWord

        if (isFirst) {
          // pick replacement from the last word
          acc.push(stringReplaceAt(word, random(0, word.length - 1), lastWord[random(0, lastWord.length - 1)]))
        } else if (isLast) {
          // pick replacement from the first word
          acc.push(stringReplaceAt(word, random(0, word.length - 1), firstWord[random(0, firstWord.length - 1)]))
        } else {
          // pick replacement from the previous word
          const previousWord = words[i - 1]
          acc.push(stringReplaceAt(word, random(0, word.length - 1), previousWord[random(0, previousWord.length - 1)]))
        }

        return acc
      }, []).join(' '),
    })

    // Set to default and run again.
    this.interval = setTimeout(async () => {
      await this.changeState({
        scrambled: this.state.original,
      })

      this.scramble()
    }, random(300, 1200))
  }

  render() {
    const { scrambled, original } = this.state

    return (
      <div className="Scrambler">
        <span className="Scrambler-original">{original}</span>
        <span className="Scrambler-scrambled" aria-hidden>{scrambled || original}</span>
      </div>
    )
  }
}
