import React, { Component } from 'react'
import { connect } from 'kea'
import { uniqueId } from 'lodash'

import applicationLogic from 'logic/app'
import { statusEnum } from 'lib/enums'

import './BlockPlaceholder.scss'

const kea = {
  actions: [
    applicationLogic, [
      'setStatus'
    ],
  ],
}

export default connect(kea)(class BlockPlaceholder extends Component {
  componentDidMount() {
    this.uid = uniqueId('bp_')

    this.actions.setStatus(this.uid, statusEnum.loading)

  }

  componentWillUnmount() {
    this.actions.setStatus(this.uid, statusEnum.ready)
  }

  getBlockClass = (name) => {
    switch (name) {
      case 'core/paragraph': {
        return 'block block--50'
      }

      case 'core/heading': {
        return 'block block--75'
      }

      case 'acf/timeline': {
        return 'block block--300'
      }

      default: {
        return 'block block--50'
      }
    }
  }

  render() {
    const { blocks } = this.props

    return (
      <div className="BlockPlaceholder">
        {blocks.map(({ blockName }, i) => (
          <div className={this.getBlockClass(blockName)} key={i}>Loading the block</div>
        ))}
      </div>
    )
  }
})
