import parse, { domToReact } from 'html-react-parser'
import DOMPurify from 'dompurify'
import React from 'react'

import config from 'lib/config'
import relativeUrl from 'lib/relative-url'
import MyLink from 'components/Link'

export function replaceNode ({ attribs, children, name: tagName, ...rest }) {
  /**
   * Replace all links with our link component
   */
  if (tagName === 'a') {
    if (attribs.href.indexOf(config.wpUrl) !== -1) {
      attribs.href = relativeUrl(attribs.href)
    }

    return (
      <MyLink to={attribs.href}>
        {domToReact(children)}
      </MyLink>
    )
  }

  if (attribs) {
    const { class: className } = attribs

    if (className === 'remove-this-element') {
      return <React.Fragment />
    }

    if (className === 'replace-parent-keep-children') {
      return (
        <div className="replaced-parent">
          {domToReact(children)}
        </div>
      )
    }
  }
}

export default function html(html, opts = {}) {
  return parse(DOMPurify.sanitize(html), {
    ...{
      replace: replaceNode,
    },
    ...opts,
  })
}
