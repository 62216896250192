import React, { Suspense } from 'react'
import BlockPlaceholder from 'components/BlockPlaceholder'
import Default from 'components/blocks/core/Default'
import { isDev } from 'lib/env'

const getRealBlockName = (blockName, prefix = 'core/') => capitalizeFirstLetter(blockName.replace(prefix, ''))

/**
 * Suspense isn't as "full-fledged" as react-loadable, and it lacks things like delay
 * for the placeholder. If the import isn't slowed down, users with good internet connections
 * see placeholders for about 50ms, and that is annoying.
 */
const throttledImport = (filename, ms = 300) => (Promise.all([
  import(`../components/blocks/${filename}`),
  new Promise(resolve => setTimeout(resolve, ms)),
]).then(([module]) => module))

/**
 * Caching all dynamically imported components removes a lot of unnecessary renders.
 * I assume that the components are being garbage collected or something. This might
 * not be necessary in production environment as the browser should cache these,
 * but development build does need it.
 */
const componentCache = new Map()

/**
 * React Suspense will display the BlockPlaceholder component until all blocks
 * are loaded and ready.
 */
export default function Blocks({ content: blocks = [], ...additionalProps }) {
  if (!Array.isArray(blocks)) {
    console.warn('Invalid data provided to Blocks, expected array, got ', blocks)

    return null
  }

  return (
    <div className="WordPressContent">
      <Suspense fallback={<BlockPlaceholder blocks={blocks} />}>
        {blocks.map((block, k) => {
          const { attrs, blockName, innerBlocks, innerHTML } = block
          let Component
          let props

          if (innerBlocks && innerBlocks.length) {
            console.warn('Nested blocks are not supported. They\'re a nightmare to parse, and it\'s not worth the effort, at least now.')
            console.warn(block)
          }

          if (blockName === null) {
            Component = React.Fragment // Most likely a line break, nothing of value at least.
          } else if (blockName.indexOf('acf/') === 0) {
            // ACF Blocks block
            const componentName = getRealBlockName(blockName, 'acf/')
            const { data, align, className } = attrs
            props = {
              ...data,
              attrs: { align, className },
              innerBlocks,
              ...additionalProps,
            }

            if (innerHTML) {
              props.rawHTML = innerHTML
            }

            if (componentCache.has(componentName)) {
              Component = componentCache.get(componentName)
            } else {
              Component = React.lazy(() => {
                // return import(`../components/blocks/acf/${componentName}`)
                return throttledImport(`acf/${componentName}`)
                  .catch(e => {
                    throw e
                  })
              })

              componentCache.set(componentName, Component)
            }

          } else if (blockName.indexOf('core/') === 0) {
            // WP core block

            const componentName = getRealBlockName(blockName, 'core/')
            props = {
              ...attrs,
              componentName,
              innerBlocks,
              ...additionalProps,
            }

            if (innerHTML) {
              props.rawHTML = innerHTML
            }

            if (componentCache.has(componentName)) {
              Component = componentCache.get(componentName)
            } else {
              Component = React.lazy(() => {
                // return import(`components/blocks/core/${componentName}`)
                  return throttledImport(`core/${componentName}`)
                  .catch((e) => {
                    if (e.message.indexOf('Cannot find module') === 0) {
                      // Nobody cares. That's what the Default block is for.
                      if (isDev()) {
                        console.log('FYI: Using Default for block.', e)
                      }

                      return {
                        default: (props) => <Default {...props} />,
                      }
                    } else {
                      throw e
                    }
                  })
              })

              componentCache.set(componentName, Component)
            }
          } else {
            console.warn(`No suitable handler was found for block ${blockName}, falling back to default`)

            props = {
              ...attrs,
              innerBlocks,
              ...additionalProps,
            }

            if (innerHTML) {
              props.rawHTML = innerHTML
            }

            Component = Default
          }

          // Blocks aren't added without rerendering everything, so using the array key is fine
          return <Component {...props} key={`block-${k}`} />
        })}
      </Suspense>
    </div>
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
