import React, { Component } from 'react'
import { Location } from '@reach/router'

let scrollPositions = {}

// https://gist.github.com/ryanflorence/39a37a85254159fd7a5ca54027e175dc
// The original used location.key, but it seems to be volatile in my case (animations or nested routers?)
// so I'm using the pathname. URL always renders the same view in this app. The original was also mounted in
// app root, this is mounted in the component that wants the restoration.

class ManageScroll extends Component {
  componentDidMount() {
    try {
      let storage = JSON.parse(sessionStorage.getItem("scrollPositions"))

      if (storage) {
        scrollPositions = storage

        // Disable scroll restoration on page refresh, annoying AF.
        // Also, if it's not delayed, it will not work properly, and
        // if it is, it looks terrible.
        /* let { pathname: key } = this.props.location

        if (scrollPositions[key]) {
          setTimeout(() => window.scrollTo(0, scrollPositions[key]), 300)
        } */
      }
    } catch (e) {
      // I don't care.
    }

    window.addEventListener("scroll", this.listener)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listener)
  }

  componentDidUpdate() {
    const { pathname: key } = this.props.location

    if (!scrollPositions[key]) {
      window.scrollTo(0, scrollPositions[key])
    } else {
      // If done immediately, mobile devices haven't rendered yet and it fails
      setTimeout(() => window.scrollTo(0, scrollPositions[key]), 300)
    }
  }

  listener = () => {
    scrollPositions[this.props.location.pathname] = window.scrollY

    try {
      sessionStorage.setItem('scrollPositions', JSON.stringify(scrollPositions))
    } catch (e) {
      // I still don't care
    }
  }

  render() {
    return null
  }
}

export default () => (
  <Location>
    {({ location }) => <ManageScroll location={location} />}
  </Location>
)
