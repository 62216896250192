import React, { Component } from 'react'
import { connect } from 'kea'

import applicationLogic from 'logic/app'

const kea = {
  actions: [
    applicationLogic, [
      'runCommand',
    ],
  ],

  props: [
    applicationLogic, [
      'commands',
      'user',
    ]
  ]
}

export default connect(kea)(class CommandLine extends Component {
  constructor() {
    super()

    this.state = {
      command: ''
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { command } = this.state

    if (command) {
      this.actions.runCommand(command)

      this.setState({
        command: '',
      })
    }
  }

  onChange = (e) => {
    const { target } = e
    const { value } = target
    const { commands } = this.props
    const { current: command } = commands

    if (command && command !== value) {
      // If the command is set manually, freeze the input.
    } else {
      this.setState({
        command: value,
      })
    }
  }

  render() {
    const { commands, user } = this.props
    const { username } = user

    const { command: localCommand } = this.state
    const { current: command } = commands

    return (
      <form className="CommandLine" onSubmit={this.onSubmit} autoComplete="off">
        <span className="SessionName">
          <strong>{username}@kisuli</strong>:~$
        </span>

        <input value={command || localCommand} onChange={this.onChange} name="command" />
      </form>
    )
  }
})
