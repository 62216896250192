import { kea } from 'kea'
import PropTypes from 'prop-types'
import { put, take } from 'redux-saga/effects'
import { channel, buffers } from 'redux-saga'
import { isMatch, isEqual } from 'lodash'

import { error } from '../lib/error'
import api from '../lib/api'
import delay from 'lib/delay'
import relativeUrl from '../lib/relative-url'
import { navigate } from '@reach/router'
import { statusEnum } from 'lib/enums'

const responseChannel = channel(buffers.expanding())


// eslint-disable-next-line
export const actionResultIs = (action, value) => {
  const name = action.toString()
  return ({ type, payload }) => {
    if (name === type && Array.isArray(value)) {
      return value.some(x => x === payload)
    }

    return name === type && isEqual(payload, value)
  }
}

// eslint-disable-next-line
export const actionResultMatchesObject = (action, object = {}) => {
  const name = action.toString()

  return ({ type, payload }) => {
    return name === type && isMatch(object, payload)
  }
}

/**
 * Check if action result object contains certain keys. Can be used to check when something has loaded
 */
export const actionResultObjectContainsKeys = (action, keys = []) => {
  const name = action.toString()

  return ({ type, payload }) => {
    return Boolean(payload) && name === type && keys.some(key => key in payload)
  }
}

export default kea({
  // path: () => ['app'], // lol this breaks everything
  path: () => ['kea', 'app'],

  actions: ({ path }) => ({
    initialize: () => true,
    loadPage: (url, next = false) => ({ url, next }),

    setStatus: (part, status) => ({ part, status }),
    setPrimaryMenu: (menu) => menu,
    setPageData: (data) => data,
    setNextPageData: (data) => data,

    addError: (error) => error,
    removeError: (index) => index,

    runCommand: (command) => command,
    setCurrentCommand: (command) => command,
    addCommandToHistory: (command) => command,
    addCommandOutput: (output) => output,

    addUnloadedBlock: (uid) => uid,
    removeUnloadedBlock: (uid) => uid,

    maybeAskForPassword: (user = 'root') => user,
    setUser: (data) => ({ ...data }),
  }),

  reducers: ({ actions, path }) => ({
    status: [{
      app: statusEnum.uninitialized, // Mainly used by this file
      primaryMenu: statusEnum.uninitialized,
      page: statusEnum.uninitialized,
     }, PropTypes.object, {
      [actions.setStatus]: (state, { part, status }) => {
        const copy = { ...state }

        copy[part] = status

        return copy
      },
    }],

    primaryMenu: [null, PropTypes.array, {
      [actions.setPrimaryMenu]: (state, menu) => ([ ...menu ])
    }],

    page: [null, PropTypes.object, {
      [actions.setPageData]: (state, data) => data ? ({ ...data }) : data,
    }],

    nextPage: [null, PropTypes.object, {
      [actions.setNextPageData]: (state, data) => data ? ({ ...data }) : data,
    }],

    user: [{ username: 'nobody' }, PropTypes.object, { persist: true },  {
      [actions.setUser]: (state, data) => ({ ...data }),
    }],

    unloadedBlocks: [[], PropTypes.object, {
      [actions.addUnloadedBlock]: (state, uid) => [...state, uid],
      [actions.removeUnloadedBlock]: (state, uid) => {
        const pos = state.indexOf(uid)

        if (pos !== -1) {
          const newState = [...state]
          newState.splice(pos, 1)

          return newState
        } else {
          return state
        }
      }
    }],

    /**
     * Note that errors can't be serialized to JSON, so you can't see the full errors in redux dev tools
     */
    errors: [[], PropTypes.array, {
      [actions.addError]: (state, error) => [...state, error ? { ...error } : error],
      [actions.removeError]: (state, index) => {
        const newErrors = [...state]
        newErrors.splice(index, 1)

        return newErrors
      }
    }],

    /**
     * This data structure sucks, before doing *anything else* with it, refactor it
     * so that the command and output are in the same object.
     */
    commands: [{ current: '', history: [], output: [] }, PropTypes.object, {
      [actions.setCurrentCommand]: (state, command) => ({ ...state, current: command }),
      [actions.addCommandToHistory]: (state, command) => ({ ...state, history: [...state.history, command] }),
      [actions.addCommandOutput]: (state, output) => ({ ...state, output: [...state.output, output] }),
    }],
  }),

  selectors: ({ selectors }) => ({
    appStatus: [
      () => [selectors.status],
      (statusMonster) => statusMonster['app'],
      PropTypes.number,
    ],

    primaryMenuStatus: [
      () => [selectors.status],
      (statusMonster) => statusMonster['primaryMenu'],
      PropTypes.number,
    ],

    pageStatus: [
      () => [selectors.status],
      (statusMonster) => statusMonster['page'],
      PropTypes.number,
    ],

    nextPageStatus: [
      () => [selectors.status],
      (statusMonster) => statusMonster['nextPage'],
      PropTypes.number,
    ],
  }),

  start: function * () {
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },

  takeEvery: ({ actions, selectors }) => ({
    /**
     * Puppeteer will capture the page when window.READY === true
     */
    [actions.setStatus]: function * () {
      const status = yield this.get('status')

      if (Object.entries(status).every(([k, v]) => v === statusEnum.ready)) {
        window.READY = true
      } else {
        window.READY = false
      }
    },

    [actions.addError]: function * () {
      const errors = yield this.get('errors')
      const last = errors[errors.length - 1]

      console.log('last error', last.error, last)
      yield put(actions.setStatus('app', statusEnum.error))
    },

    [actions.removeError]: function * () {
      const errors = yield this.get('errors')

      if (!errors.length) {
        // Let's try this again.
        yield put(actions.setStatus('app', statusEnum.uninitialized))
      }
    },

    [actions.runCommand]: function * ({ payload: command }) {
      yield put(actions.setCurrentCommand(command))

      if (command.indexOf('cd') === 0) {
        const path = command.trim().replace('cd ', '')

        navigate(path)
      } else if (command.indexOf('su') === 0) {
        // This is sloppy but I do not care
        const user = command.replace('su', '').trim()

        yield put(actions.maybeAskForPassword(user ? user : 'root'))
      } else {
        yield put(actions.addCommandToHistory(command))

        let output
        try {
          // eslint-disable-next-line
          output = eval(command)

          if (typeof output === 'function') {
            output = 'function'
          } else if (typeof output === 'object') {
            output = JSON.stringify(Object.getOwnPropertyNames(output), null, 2)
          } else {
            output = JSON.stringify(output, null, 2)
          }
        } catch (e) {
          if (e.name === 'ReferenceError') {
            console.error(error)
            output = error['1D10T'](e.message)
          } else {
            output = e
          }
        }

        yield put(actions.addCommandOutput(output))
      }

      yield put(actions.setCurrentCommand(''))
    },

    /**
     * Be very fucking careful with this. Each call will be completed, and they take at least 300ms.
     */
    [actions.loadPage]: function * ({ payload }) {
      const { url, next } = payload
      const statusName = next ? 'nextPage' : 'page'
      const setData = actions[next ? 'setNextPageData' : 'setPageData']
      const current = yield this.get(next ? 'nextPage' : 'page')

      if (current && current.link) {
        const relative = relativeUrl(current.link)

        if (relative === url) {
          console.warn('Skipping duplicate call to loadPage. If this is happening multiple times in a row, you have a problem.', url)
          return
        }
      }

      // console.log('loadPage', next ? 'nextPage' : 'page', next ? 'setNextPageData' : 'setPageData', url)

      yield put(actions.setStatus(statusName, statusEnum.loading))

      try {
      // loading of the next page is a transparent process
        if (next) {
          yield put(setData(null))
          const req = yield api.resolveURL(url);
          yield put(setData({ ...req.data }))

          yield put(actions.setStatus(statusName, statusEnum.ready))
        } else {
          yield put(setData(null))
          yield put(actions.setCurrentCommand(`cd ${window.location.pathname}`))

          // This is too fast when hitting the cache... Time it to throttle it.
          const start = Date.now()
          const req = yield api.resolveURL(url)
          const timeTook = Date.now() - start

          yield put(setData({ ...req.data }))
          yield put(actions.setStatus(statusName, statusEnum.ready))

          yield delay(300 - timeTook) // Ensure previous state is visible for at least 300ms
          yield put(actions.setCurrentCommand(''))
        }
      } catch (e) {
        yield put(setData(e))
        yield put(actions.setStatus(statusName, statusEnum.error))
        yield put(actions.setCurrentCommand(''))

        if (e.error === 'NOT_FOUND') {
          console.warn('loadPage finished with a 404. It should be handled by the components.', url, e.error)
        } else if (e.error === 'AUTH_REQUIRED') {
          console.warn('loadPage finished with a 401. It should be handled by the components.', url, e.error)
        } else {
          // This crashes the application
          console.error(e)
          yield put(actions.addError(e))
        }
      }
    },
  }),

  takeLatest: ({ actions }) => ({
    [actions.initialize]: function * () {
      yield put(actions.setStatus('app', statusEnum.loading))

      try {
        yield put(actions.setStatus('primaryMenu', statusEnum.loading))
        const { data } = yield api.getMenu()
        const mapItems = (item, i, orig) => {
          return {
            itemID: item.ID,
            parentID: parseInt(item.menu_item_parent, 10),
            text: item.title,
            href: relativeUrl(item.url),
            type: item.object, // If more fine tuned type is required, there's more data in the object
            target: item.target,
            children: orig.filter(x => parseInt(x.menu_item_parent, 10) === item.ID).map(mapItems)
          }
        }

        // Extract data out of the items and unflatten the data,
        // because apparently I want to use recursion for the menus.
        const items = data.items.map(mapItems).filter(item => {
          return item.parentID === 0
        })

        yield put(actions.setPrimaryMenu(items))
        yield put(actions.setStatus('primaryMenu', statusEnum.ready))
      } catch (e) {
        yield put(actions.setStatus('primaryMenu', statusEnum.ready))
        if (e.error === 'BAD_REQUEST') {
          console.error('Failed to get menu, or menu is empty.')
        } else {
          yield put(actions.addError(error.initializationError(e)))
        }
      }

      yield put(actions.setStatus('app', statusEnum.ready))
    },

    /**
     * Authenticate here when it makes sense to have authentication.
     * Modal, prompt(), whatever.
     */
    [actions.maybeAskForPassword]: function * ({ payload: username }) {
      navigate('/hack')

      yield put(actions.setUser({ username }))
    },
  })

})
