import React from 'react'
import Scrambler from 'components/blocks/acf/Scrambler'

export default function ScrambledTitle ({ title = '', element: Tag = 'h1' }) {
  if (!title) {
    return null
  }

  return (
    <Tag>
      {title.split(' ').map((chunk, i, org) => (
        <React.Fragment key={`c-${chunk}`}>
          <Scrambler scramble={chunk} />

          {org.length - 1 !== i ? ' ' : ''}
        </React.Fragment>
      ))}
    </Tag>
  )
}
