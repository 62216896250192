import React from 'react'

/*
 * Component for easy display of image.
 */
const Image = ({ image, size = null, ...remaining }) => {
  if (!image) {
    return false
  }

  // I don't have any use for mediaDetails at this time, and I don't want it in the component
  const { mediaDetails, ...data } = getImageData(image, size)
  const { width, height } = mediaDetails

  // Lint can stfu, the alt attr is there
  // eslint-disable-next-line
  return <img {...data} width={width} height={height} {...remaining} />
}

export default Image

/*
 * Takes a sizes object and returns a srcet string
 */
const createSrcset = (sizes) => Object.keys(sizes).reverse().map(
  (key) => `${sizes[key].src} ${sizes[key].width}w`
).join(', ')

/**
 * Takes a sizes object and returns a sizes string
 */
const createSizes = (sizes) => Object.entries(sizes).reverse().reduce((acc, [size, { width }]) => {
  if (size !== 'full') {
    acc = `(max-width: ${width}px) ${width}px, ` + acc
  }

  return acc
}, `${sizes.full.width}px`).replace(/,\s*$/, "")

const getAcfImageData = (image, size) => {
  const src = image.url
  const alt  = image.alt
  const caption = image.caption
  const mediaDetails = {
    width: image.width,
    height: image.height,
  }
  const sizes = Object.keys(image.sizes).reduce((acc, sizeKey) => {
    if (sizeKey.includes('-height')) {
      const value = image.sizes[sizeKey]
      const k = sizeKey.replace('-height', '')
      acc[k].height = value
    } else if (sizeKey.includes('-width')) {
      const value = image.sizes[sizeKey]
      const k = sizeKey.replace('-width', '')
      acc[k].width = value
    } else {
      acc[sizeKey] = {
        src: image.sizes[sizeKey],
      }
    }

    return acc
  }, {})

  return {
    src, alt, caption, mediaDetails, sizes
  }
}

const getWpImageData = (image, size) => {
  const src = image.source_url
  const alt = image.alt_text
  const caption = image.caption && image.caption.rendered
  const sizes = Object.entries(image.media_details.sizes).reduce((acc, [size, { width, height, source_url: src }]) => {
    acc[size] = {
      width,
      height,
      src,
    }

    return acc
  }, {})
  const mediaDetails = {
    width: image.media_details.width,
    height: image.media_details.height,
  }

  return {
    src, alt, caption, mediaDetails, sizes
  }
}

/*
 * Takes an image object (WP or ACF), and returns a cleaned up version of it.
 */
export const getImageData = (image, size) => {
  if (!image) return false

  const { src: originalSrc, alt, mediaDetails, sizes } = image.sizes ? getAcfImageData(image, size) : getWpImageData(image, size)
  let src = originalSrc

  const isSVG = src.indexOf('.svg') !== -1

  if (size && !isSVG && sizes[size]) {
    src = sizes[size].src
  }

  // sizes is empty for small images
  const srcSet = isSVG || !sizes.full ? null : createSrcset(sizes)
  const sizesAttr = isSVG || !sizes.full ? null : createSizes(sizes)

  return {
    srcSet,
    sizes: sizesAttr,
    src,
    alt,
    mediaDetails,
  }
}

