import React, { Component } from 'react'
import { connect } from 'kea'
import applicationLogic from 'logic/app'

import Link from './Link'
import CommandLine from './CommandLine'

import './Navigation.scss'
import { statusEnum } from 'lib/enums'

function renderItem(item, i) {
  const { text, href, type, itemID, children } = item
  const linkProps = {
    to: href,
    exact: type === 'page', // Force exact when menu item is a page and the url matches,
    nav: true,
  }

  return (
    <li key={itemID}>
      <Link {...linkProps} className="Item">
        {/* <span className="ItemNumber" aria-hidden="true">
          {i + 1}
        </span> */}

        <span className="ItemText">
         {text}
        </span>

        {/* <span className="ItemArrow"></span> */}
      </Link>

      {renderItems(children)}
    </li>
  )
}

function renderItems(items) {
  if (!items || !items.length) {
    return null
  }

  return (
    <ul>
      {items.map((item, i) => renderItem(item, i))}
    </ul>
  )
}

const kea = {
  props: [
    applicationLogic, [
      'primaryMenuStatus',
      'primaryMenu',
    ]
  ]
}


export default connect(kea)(class Navigation extends Component {
  render() {
    const { primaryMenu, primaryMenuStatus } = this.props

    if (primaryMenuStatus !== statusEnum.ready) {
      return null
    }

    return (
      <nav className="Navigation">
        <CommandLine />

        <div className="Items">
          {renderItems(primaryMenu)}
        </div>
      </nav>
    )
  }
})
